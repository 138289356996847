define("discourse/plugins/discourse-password-expiry/discourse/templates/connectors/discovery-list-container-top/password-expiry-warning", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.password_expiry_warning}}
    <PasswordExpiryWarning />
  {{/if}}
  */
  {
    "id": "+pyEYXkq",
    "block": "[[[41,[30,0,[\"currentUser\",\"password_expiry_warning\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"password-expiry-warning\"]]",
    "moduleName": "discourse/plugins/discourse-password-expiry/discourse/templates/connectors/discovery-list-container-top/password-expiry-warning.hbs",
    "isStrictMode": false
  });
});